import type { IconifyJSON } from '@iconify/react';

/**
 * Before adding new icons, please make sure with the designer(s) that there is not aready one available in Iconify that could be used instead.
 *
 * Adding icons:
 * - make sure the icon has a 24*24 viewbox. If not, resize the icon in figma before re-exporting it.
 * - optimize the icon using https://jakearchibald.github.io/svgomg/, will all options checked, number precision 3, and transform precision 5.
 * - replace the fill color with "currentColor"
 */

export const aaiCollection: IconifyJSON = {
  prefix: 'aai',
  width: 24,
  height: 24,
  icons: {
    'machine-learning': {
      body: '<path fill="currentColor" d="M9.861 2.012a1.001 1.001 0 0 0-.744.531c-.2.389-.121.86.194 1.164l5.054 4.887c.144.139.327.231.524.267l6.94 1.215a1 1 0 0 0 1.065-.525.996.996 0 0 0-.197-1.168l-5.052-4.887a1.016 1.016 0 0 0-.524-.267l-6.94-1.215a1.023 1.023 0 0 0-.32-.002ZM4 4c-1.11 0-2 .89-2 2v10a2 2 0 0 0 2 2h16c1.1 0 2-.9 2-2v-3.91c-.17 0-.34-.01-.52-.04l-.48-.08-1-.18V16H4V6h4.8l-.88-.85c-.05-.05-.1-.1-.14-.15-.06-.06-.11-.12-.16-.19a1.433 1.433 0 0 1-.14-.2c-.12-.19-.22-.4-.3-.61H4Zm6.34 3.49L9.08 9.97s1.64.83 3.27 3.03c2.75.03 4.39.86 4.39.86l1.24-2.42-3.45-.61a3 3 0 0 1-1.56-.8l-2.63-2.54ZM8 20v2h8v-2H8Z"/>',
    },
    'customer-support': {
      body: '<path fill="currentColor" d="M10.578 2.012a8.275 8.275 0 0 0-.855.088c-4.238.66-7.052 4.616-6.684 8.84.157 1.81.861 2.889 1.482 3.785C5.298 15.845 6 16.764 6 19.24V22h9v-3h2c1.093 0 2-.907 2-2v-2.979l1.227-.517c.56-.236.82-1.089.498-1.596L18.947 9.12v-.002c-.458-4.185-4.126-7.318-8.369-7.105ZM10 4.08v5.191A2 2 0 0 0 9 11a2 2 0 0 0 2.518 1.932l1.486 1.486a1.5 1.5 0 1 0 1.414-1.414l-1.488-1.488A2 2 0 0 0 13 11a2 2 0 0 0-1-1.73V4.08a5.951 5.951 0 0 1 4.959 5.256l.05.463.25.394 1.202 1.885-1.461.617V17h-4v3H8v-.76c0-2.912-1.058-4.533-1.834-5.654-.62-.896-1.012-1.4-1.135-2.818v-.002C4.75 7.528 6.845 4.589 10 4.08Z"/>',
    },
    'export-download': {
      body: '<path fill="currentColor" d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8.172l-.586-.586L12.172 20H6v-4h10v-2H6v-4h12v2h2V4a2 2 0 0 0-2-2H6Zm0 2h12v4H6V4Zm12 10v6h-3l4 4 4-4h-3v-6h-2Z"/>',
    },
    'col-resize': {
      body: '<path fill="currentColor" d="M6 2c-1.093 0-2 .907-2 2v16c0 1.093.907 2 2 2h4c1.093 0 2-.907 2-2V4c0-1.093-.907-2-2-2H6Zm8 0v2h4v4h-4v2h4v4h-4v2h4v4h-4v2h4c1.093 0 2-.907 2-2V4c0-1.093-.907-2-2-2h-4ZM6 4h4v4H6V4Zm0 6h4v4H6v-4Zm0 6h4v4H6v-4Z"/>',
    },
  },
};
