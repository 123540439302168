/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { startTransition, StrictMode, useEffect } from 'react';
import { initPosthog } from '@aether/tracking';
import { initCustomIcons } from '@aether/ui/Icon';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { hydrateRoot } from 'react-dom/client';

Sentry.init({
  dsn: 'https://93f662639080d0947a6b0ad45902b51d@o4504649976184832.ingest.us.sentry.io/4507089752883200',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

initCustomIcons();

if (document) {
  initPosthog({ key: window.ENV.POSTHOG_KEY, host: window.ENV.POSTHOG_HOST });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
