import { addCollection } from '@iconify/react';

import { aaiCollection } from './aai-icons';

export function initCustomIcons() {
  addCollection(aaiCollection);
}

initCustomIcons();

export { Icon } from '@iconify/react';
